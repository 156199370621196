import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  TextField,
  Container,
} from '@mui/material';
import { API_BASE_URL } from '../constants/APIConstants';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { formatDate } from './WaitlistDashboard';
import * as XLSX from 'xlsx';



const BrandAmbassadorDashboard = () => {
  const [currentTab, setCurrentTab] = useState('pending');
  const [tableData, setTableData] = useState([]);
  const [tabCounts, setTabCounts] = useState({ pending: 0, approved: 0, rejected: 0 });
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(''); // Added for search functionality
  const navigate = useNavigate();

  const fetchData = async (status) => {
    setLoading(true);
    const apiUrl = `${API_BASE_URL}/api/data/getAllBrandAmbassadorUsingStatus?status=${status}`;
    console.log('Fetching data from API:', apiUrl);

    try {
      const token = await auth.currentUser.getIdToken();
      console.log('Fetched token:', token);
      const response = await axios.get(apiUrl, { headers: { Authorization: `Bearer ${token}` } });
      console.log('API response:', response.data);

      setTableData(response.data || []);
      setTabCounts((prevCounts) => ({
        ...prevCounts,
        [status]: Array.isArray(response.data) ? response.data.length : 0,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setTableData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentTab);
  }, [currentTab]);

  const handleTabChange = (tab) => {
    if (tab !== currentTab) {
      setCurrentTab(tab);
    }
  };

  const handleRefresh = () => {
    fetchData(currentTab);
  };
  const convertToTitleCase = (camelCaseString) => {
  if (!camelCaseString || typeof camelCaseString !== 'string') {
    return ''; // Return an empty string if the input is not valid
  }
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add a space before each uppercase letter
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

  const exportToExcel = () => {
    // Filter out 'accountId' and 'accountProfileURL' from the table data
    const filteredData = tableData.map(({ accountId, accountProfileURL, ...rest }) => rest);
  
    // Convert column headers to title case
    const headers = Object.keys(filteredData[0] || {}).map(convertToTitleCase);
    const dataWithHeaders = [headers, ...filteredData.map(Object.values)];
  
    // Create a worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    const workbook = XLSX.utils.book_new();
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Waitlist');
  
    // Write the workbook to a file
    XLSX.writeFile(workbook, 'WaitlistData.xlsx');
  };
  const filteredData = tableData.filter(
    (row) =>
      row.accountDetails?.accountName?.toLowerCase().includes(searchText.toLowerCase()) ||
      row.accountDetails?.accountEmail?.toLowerCase().includes(searchText.toLowerCase())
  );


  return (
    <Container
      maxWidth={false}
      style={{
        marginTop: '150px',
        padding: '0 20px',
        width: '100%',
      }}
    >
      {/* Status Cards */}
      <Box display="flex" justifyContent="space-between" mb={3} mt={2}>
        {['pending', 'approved', 'rejected'].map((status) => {
          const cardStyles = {
            pending: { bgColor: '#ffe680', hoverColor: '#fff8b3' },
            approved: { bgColor: '#a5e39d', hoverColor: '#b8ebc6' },
            rejected: { bgColor: '#ff9999', hoverColor: '#ffcccc' },
          };

          return (
            <Card
              key={status}
              onClick={() => setCurrentTab(status)}
              style={{
                flex: 1,
                margin: '0 10px',
                backgroundColor: cardStyles[status].bgColor,
                cursor: 'pointer',
                border: currentTab === status ? '2px solid #1976d2' : '1px solid #ccc',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                transition: 'transform 0.3s ease, background-color 0.3s ease',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = cardStyles[status].hoverColor)}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = cardStyles[status].bgColor)}
            >
              <CardContent>
                <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </Typography>
                <Typography variant="h4" align="center">
                  {tabCounts[status]}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>

      {/* Search, Refresh, and Export */}
      <Box display="flex" justifyContent="flex-start" gap="20px" mb={3}>
      <TextField
          variant="outlined"
          placeholder="Search by Name or Email"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          size="small"
          style={{ flex: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleRefresh} startIcon={<RefreshIcon />}>
          Refresh
        </Button>
        <Button variant="contained" color="secondary" onClick={exportToExcel}>
          Export
        </Button>
      </Box>

      {/* Table */}
      <TableContainer component={Paper} style={{
      marginTop: '20px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px',
    }}>
        <Table style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
          <TableHead>
            <TableRow>
              {['Name', 'Email', 'Region', 'Date Joined', 'Approved At'].map((header, index) => (
                <TableCell  key={index}
                style={{
                  fontWeight: 'bold',
                  borderBottom: '1px solid #ccc',
                  backgroundColor: '#d4d4d4',
                }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableData.length > 0 ? (
              tableData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.accountDetails.accountName}</TableCell>
                  <TableCell>{row.accountDetails.accountEmail}</TableCell>
                  <TableCell>{row.referralCode || 'N/A'}</TableCell>
                  <TableCell>{formatDate(row.brandAmbassadorAppliedAt) || 'N/A'}</TableCell>
                  <TableCell>{formatDate(row.brandAmbassadorApprovedAt) || 'N/A'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default BrandAmbassadorDashboard;