import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  Grid,
  Card,
  CardContent,
  Snackbar,
  Alert,
  Avatar,
} from '@mui/material';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube, FaGlobe } from 'react-icons/fa';
import { API_BASE_URL } from '../constants/APIConstants';
import { auth } from '../firebase';

const AccountDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { account } = location.state || {};
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [accountStatus, setAccountStatus] = useState(account.status);

  useEffect(() => {
    if (account) {
      console.log("Account data:", account);
    } else {
      console.warn("No account data available.");
    }
  }, [account]);

  const handleApprove = async () => {
    try {
            const token = await auth.currentUser.getIdToken();
      
      const response = await axios.put(
        `${API_BASE_URL}/api/admin/updateWaitlistStatus?accountId=${account.accountId}&status=approved`,
        {},
        {
          headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          },
        }
      );
      setFeedbackMessage('Account has been approved!');
      setAccountStatus('approved');
      setShowFeedback(true);
    } catch (error) {
      setFeedbackMessage('Failed to approve account.');
      setShowFeedback(true);
    }
  };

  const handleReject = async () => {
    try {
      const token = await auth.currentUser.getIdToken();

      const response = await axios.put(
        `${API_BASE_URL}/api/admin/updateWaitlistStatus?accountId=${account.accountId}&status=rejected`,
        {},
        {
          headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
          },
        }
      );
      setFeedbackMessage('Account has been rejected!');
      setAccountStatus('rejected');
      setShowFeedback(true);
    } catch (error) {
      setFeedbackMessage('Failed to reject account.');
      setShowFeedback(true);
    }
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };

  if (!account) {
    return <div>No account data available.</div>;
  }

  const getSocialMediaIcon = (platform) => {
    switch (platform.toLowerCase()) {
      case 'facebook':
        return <FaFacebook size={24} />;
      case 'twitter':
        return <FaTwitter size={24} />;
      case 'linkedin':
        return <FaLinkedin size={24} />;
      case 'instagram':
        return <FaInstagram size={24} />;
      case 'youtube':
        return <FaYoutube size={24} />;
      case 'website':
        return <FaGlobe size={24} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#f4f5f7', minHeight: '100vh' }}>
      <AppBar position="static" style={{ backgroundColor: '#003366' }}>
        <Toolbar>
          <Typography variant="h5" style={{ color: '#ffffff', textAlign: 'center', flexGrow: 1 }}>
            Account Profile
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {/* Profile Section */}
        <Grid item xs={12} sm={4}>
          <Card style={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
            <Avatar
              src={account.accountProfileURL}
              alt="Profile"
              style={{
                width: '150px',
                height: '150px',
                margin: '0 auto',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              }}
            />
            <Typography variant="h5" style={{ marginTop: '15px', fontWeight: 'bold' }}>
              {account.name}
            </Typography>
            <Typography variant="body1" style={{ color: '#666666' }}>
              {account.email}
            </Typography>
            <Typography variant="body1" style={{ marginTop: '5px' }}>
              Status: <span style={{ fontWeight: 'bold', color: accountStatus === 'approved' ? 'green' : accountStatus === 'rejected' ? 'red' : 'orange' }}>{accountStatus}</span>
            </Typography>
          </Card>
        </Grid>

        {/* Account Details */}
        <Grid item xs={12} sm={8}>
          <Card style={{ padding: '20px', backgroundColor: '#ffffff' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Account Details
            </Typography>
            <Typography variant="body1">
              <strong>Phone:</strong> {account.contact || 'Not Provided'}
            </Typography>
            <Typography variant="body1">
              <strong>City:</strong> {account.accountCity || 'Not Provided'}
            </Typography>
            <Typography variant="body1">
              <strong>Date of Birth:</strong> {account.dob || 'Not Provided'}
            </Typography>
            <Typography variant="body1">
              <strong>Description:</strong> {account.accountDescription || 'No Description Available'}
            </Typography>
          </Card>
        </Grid>

        {/* Portfolio Images */}
        <Grid item xs={12}>
          <Card style={{ padding: '20px', backgroundColor: '#ffffff' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
              Portfolio Images
            </Typography>
            <Grid container spacing={2}>
              {account.portfolioCollections && account.portfolioCollections.length > 0 ? (
                account.portfolioCollections.map((collection) =>
                  collection.portfolioDocuments.map((document, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <img
                        src={document.imageURL}
                        alt="Portfolio"
                        style={{
                          width: '100%',
                          borderRadius: '10px',
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                          transition: 'transform 0.2s',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                      />
                    </Grid>
                  ))
                )
              ) : (
                <Typography>No portfolio images available.</Typography>
              )}
            </Grid>
          </Card>
        </Grid>

        {/* Social Media Links */}
        <Grid item xs={12}>
          <Card style={{ padding: '20px', backgroundColor: '#ffffff' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
              Social Media Links
            </Typography>
            {account.socialLinks && Object.values(account.socialLinks).some((url) => url) ? (
              <Grid container spacing={2}>
                {Object.entries(account.socialLinks).map(
                  ([platform, url]) =>
                    url && (
                      <Grid item key={platform}>
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: '#003366', fontSize: '18px' }}
                        >
                          {getSocialMediaIcon(platform)}
                          <Typography variant="body2" style={{ marginLeft: '10px', display: 'inline-block' }}>
                            {platform.charAt(0).toUpperCase() + platform.slice(1)}
                          </Typography>
                        </a>
                      </Grid>
                    )
                )}
              </Grid>
            ) : (
              <Typography>No social media links available.</Typography>
            )}
          </Card>
        </Grid>

        {/* Account Action */}
        <Grid item xs={12}>
          <Card style={{ padding: '20px', backgroundColor: '#ffffff', textAlign: 'center' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Account Actions
            </Typography>
            {accountStatus === 'approved' ? (
              <Typography style={{ color: 'green' }}>Account Approved</Typography>
            ) : accountStatus === 'rejected' ? (
              <Typography style={{ color: 'red' }}>Account Rejected</Typography>
            ) : (
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="success" onClick={handleApprove}>
                    Approve
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="error" onClick={handleReject}>
                    Reject
                  </Button>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>

      <Snackbar open={showFeedback} autoHideDuration={6000} onClose={handleCloseFeedback}>
        <Alert onClose={handleCloseFeedback} severity="info">
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AccountDetails;