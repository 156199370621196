import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import WaitlistDashboard from './component/WaitlistDashboard';
import AccountDetails from './component/AccountDetails';
import Login from './component/Login'; 
import Profile from './component/profile'; 
import Navbar from './component/navbar';
import BrandAmbassadorDashboard from './component/BrandAmbassadorDashboard'; 
import { auth } from './firebase';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); // Update user state when authentication changes
      setLoading(false);    // Stop loading indicator
    });
    return () => unsubscribe(); // Cleanup subscription
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading state until user auth is checked
  }

  return (
    <Router>
      <div className="root">
        {user && <Navbar />} {/* Render Navbar only when the user is logged in */}
        <Routes>
          {/* Redirect '/' to '/profile' when user is logged in */}
          <Route
            path="/"
            element={<Navigate to={user ? "/profile" : "/login"} />}
          />
          <Route
            path="/waitlist-dashboard"
            element={user ? <WaitlistDashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/AccountDetails/:accountId"
            element={user ? <AccountDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile"
            element={user ? <Profile /> : <Navigate to="/login" />}
          />
          <Route
            path="/login"
            element={user ? <Navigate to="/Profile" /> : <Login />}
          />
          <Route
            path="/brand-ambassador-dashboard"
            element={user ? <BrandAmbassadorDashboard /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;