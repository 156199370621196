import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  TextField,
  Button,
  Container,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { auth } from '../firebase';
import { updateProfile, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserData({
        name: currentUser.displayName || '',
        email: currentUser.email,
        profilePic: currentUser.photoURL || 'https://via.placeholder.com/150',
        emailId: currentUser.uid,
        phoneNumber: currentUser.phoneNumber || '',
        dateJoined: currentUser.metadata.creationTime || 'N/A',
        location: 'Not Set',
        bio: 'This is your bio. You can update it to reflect your personality.',
      });
    }
  }, []);

  const handleEditClick = () => {
    setShowPasswordDialog(true); // Open password dialog
  };

  const handlePasswordSubmit = async () => {
    setError(null);
    const currentUser = auth.currentUser;

    if (currentUser && password) {
      const credential = EmailAuthProvider.credential(currentUser.email, password);

      try {
        // Reauthenticate user
        await reauthenticateWithCredential(currentUser, credential);
        setShowPasswordDialog(false);
        setIsEditing(true); // Enable editing mode
      } catch (err) {
        setError('Incorrect password. Please try again.');
      }
    }
  };

  const handleSave = async () => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      try {
        // Update user's profile
        await updateProfile(currentUser, {
          displayName: userData.name,
          photoURL: userData.profilePic,
        });
        setIsEditing(false); // Disable editing mode
        alert('Profile updated successfully!');
      } catch (err) {
        alert('Failed to update profile. Please try again.');
      }
    }
  };

  if (!userData) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f6f6f6',
        }}
      >
        <Typography variant="h6" color="textSecondary">
          Loading profile...
        </Typography>
      </div>
    );
  }

  return (
<div
  style={{
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    background: `linear-gradient(150deg, #b7e9f7 0%, #a1e3f5 50%, #7ad7f0 100%)`, // Smoother gradient
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingTop: '100px', // Adds spacing below the navbar
  }}
>
  
      <Container style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <Card
          style={{
            width: '400px',
            padding: '20px',
            borderRadius: '15px',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
            background: `linear-gradient(135deg, #ffffff, #b7e9f7)`,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Avatar
              alt={userData.name}
              src={userData.profilePic}
              style={{
                width: '120px',
                height: '120px',
                border: '3px solid #ffffff',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            />
          </div>
          <CardContent>
            <Typography
              variant="h4"
              style={{
                textAlign: 'center',
                marginBottom: '10px',
                fontWeight: 'bold',
                color: '#333333',
              }}
            >
              {isEditing ? 'Edit Profile' : 'Profile'}
            </Typography>

            {/* Profile Fields */}
            {['name', 'email', 'phoneNumber', 'location', 'bio'].map((field) => (
              <Box mb={2} key={field}>
                <TextField
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={userData[field]}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: !isEditing,
                  }}
                  onChange={(e) =>
                    setUserData((prev) => ({ ...prev, [field]: e.target.value }))
                  }
                  style={{ backgroundColor: '#ffffff', borderRadius: '5px' }}
                  multiline={field === 'bio'}
                  rows={field === 'bio' ? 3 : 1}
                />
              </Box>
            ))}

            {/* Edit or Save Button */}
            <Button
              variant="contained"
              style={{
                width: '100%',
                marginTop: '20px',
                backgroundColor: isEditing ? '#4caf50' : '#35cde1',
                color: '#000000',
                padding: '10px 0',
                borderRadius: '25px',
                fontWeight: 'bold',
                fontSize: '16px',
              }}
              onClick={isEditing ? handleSave : handleEditClick}
            >
              {isEditing ? 'Save Changes' : 'Edit Profile'}
            </Button>
          </CardContent>
        </Card>
      </Container>

      {/* Password Dialog */}
      <Dialog open={showPasswordDialog} onClose={() => setShowPasswordDialog(false)}>
        <DialogTitle>Enter Your Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit your profile, please confirm your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={Boolean(error)}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPasswordDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;