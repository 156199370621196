import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  CircularProgress, AppBar, Toolbar, Typography, IconButton, TextField, Box, Container, Pagination, Card, CardContent,
} from '@mui/material';
import { ExitToApp as ExitToAppIcon, SaveAlt as SaveAltIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { API_BASE_URL } from '../constants/APIConstants';
import * as XLSX from 'xlsx';
import { auth } from '../firebase';
import { format, parseISO } from 'date-fns'; // Add date-fns for date formatting
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
// Function to convert ISO date to readable format

const WaitlistDashboard = () => {
  const [currentTab, setCurrentTab] = useState('pending');
  const [tableData, setTableData] = useState([]);
  const [tabCounts, setTabCounts] = useState({ pending: 0, approved: 0, rejected: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Add this new state after your other useState declarations
const [filters, setFilters] = useState({
  portfolioImages: null,
  isEmailVerified: null,
  hasDescription: null,
  socialMediaLinked: null
});const handleFilterChange = (filterName, value) => {
  console.log('Filter changed:', filterName, value);
  setFilters(prev => ({
    ...prev,
    [filterName]: value === prev[filterName] ? null : value
  }));
  setCurrentPage(1);
};


  const handleViewProfile = (accountId) => {
    const selectedAccount = tableData.find((account) => account.accountId === accountId);
    navigate(`/AccountDetails/${accountId}`, {
      state: {
        account: selectedAccount,
        portfolioImages: selectedAccount.portfolioCollections,
      },
    });
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred while logging out. Please try again.');
    }
  };

  const fetchData = async (status) => {
    setLoading(true);
    const apiUrl = `${API_BASE_URL}/api/admin/waitlist/accounts?status=${status}`;
  
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(apiUrl, { headers: { Authorization: `Bearer ${token}` } });
  
      const transformedData = response.data.map((item) => ({
        accountId: item.accountDetails?.accountId || 'N/A',
        name: item.accountDetails?.accountName || 'N/A',
        email: item.accountDetails?.accountEmail || 'N/A',
        contact: item.accountDetails?.accountPhone || 'N/A',
        portfolioImages: item.portfolioCollections?.some(
          (collection) => Array.isArray(collection.portfolioDocuments) && collection.portfolioDocuments.length > 0
        )
          ? 'Yes'
          : 'No',
        isEmailVerified: item.emailVerified,
        accountDescription: item.accountDetails?.accountDescription || 'N /A',
        socialMediaLinked: item.accountDetails?.socialMediaLinked ,
        accountCreatedAt: formatDate(item.accountDetails?.accountCreatedAt),
        accountProfileURL: item.accountDetails?.accountProfileURL || 'No',
        accountCategory: convertToTitleCase(item.accountDetails?.accountCategory) || 'No',
        accountSubCategory: convertToTitleCase(item.accountDetails?.accountSubCategory) || 'No',
      }));
  
      setTableData(transformedData);
  
      // Update tab counts
      setTabCounts((prevCounts) => ({
        ...prevCounts,
        [status]: transformedData.length,
      }));
  
      localStorage.setItem(`waitlistData-${status}`, JSON.stringify(transformedData));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    // Filter out 'accountId' and 'accountProfileURL' from the table data
    const filteredData = tableData.map(({ accountId, accountProfileURL, ...rest }) => rest);
  
    // Convert column headers to title case
    const headers = Object.keys(filteredData[0] || {}).map(convertToTitleCase);
    const dataWithHeaders = [headers, ...filteredData.map(Object.values)];
  
    // Create a worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    const workbook = XLSX.utils.book_new();
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Waitlist');
  
    // Write the workbook to a file
    XLSX.writeFile(workbook, 'WaitlistData.xlsx');
  };
  

  useEffect(() => {
    const cachedData = localStorage.getItem(`waitlistData-${currentTab}`);
    if (cachedData) {
      setTableData(JSON.parse(cachedData));
    } else {
      fetchData(currentTab);
    }
  }, [currentTab]);

  const filteredData = tableData.filter((row) => {
    const matchesSearch = row.name.toLowerCase().includes(searchText.toLowerCase()) ||
      row.email.toLowerCase().includes(searchText.toLowerCase());
  
    const matchesFilters = (
      (filters.portfolioImages === null || row.portfolioImages === (filters.portfolioImages ? 'Yes' : 'No')) &&
      (filters.isEmailVerified === null || row.isEmailVerified === filters.isEmailVerified) &&
      (filters.hasDescription === null || (row.accountDescription !== 'N/A') === filters.hasDescription) &&
      (filters.socialMediaLinked === null || row.socialMediaLinked === filters.socialMediaLinked )
    );
  
    return matchesSearch && matchesFilters;
  });
  const handleRefresh = () => {
    fetchData(currentTab);  // Fetch data for the current tab
  };
  const currentPageData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Container
  maxWidth={false} // Ensures the container spans the full width
  style={{
    marginTop: '150px',
    padding: '0 20px', // Adds padding for better alignment
    width: '100%',
  }}
>

    {/* Status Cards and Refresh Button */}
    <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap="20px" style={{ width: '100%' }}>
    {/* Status Cards */}
    <Box display="flex" justifyContent="space-between" flex={3} gap="20px" style={{ width: '100%' }}>
      {['pending', 'approved', 'rejected'].map((status) => {
        const getCardStyles = () => {
          switch (status) {
            case 'pending':
              return { bgColor: '#ffecb3', hoverColor: '#ffd54f' };
            case 'approved':
              return { bgColor: '#c8e6c9', hoverColor: '#81c784' };
            case 'rejected':
              return { bgColor: '#ffcdd2', hoverColor: '#e57373' };
            default:
              return { bgColor: '#f0f0f0', hoverColor: '#e0e0e0' };
          }
        };

        const { bgColor, hoverColor } = getCardStyles();

        return (
          <Card
            key={status}
            onClick={() => setCurrentTab(status)}
            style={{
              cursor: 'pointer',
              flex: 1,
              border: currentTab === status ? '2px solid #1976d2' : '1px solid #ccc',
              backgroundColor: bgColor,
              borderRadius: '15px',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0px 8px 20px rgba(0, 0, 0, 0.2)';
              e.currentTarget.style.backgroundColor = hoverColor;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.1)';
              e.currentTarget.style.backgroundColor = bgColor;
            }}
          >
            <CardContent>
              <Typography variant="h6" align="center" style={{ color: '#0f1e35', fontWeight: 'bold' }}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </Typography>
              <Typography variant="h4" align="center" style={{ color: '#0f1e35', fontWeight: 'bold' }}>
                {tabCounts[status] || 0}
              </Typography>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  </Box>


    {/* Search Bar, Refresh Button, and Export Button */}
<Box
  display="flex"
  justifyContent="flex-start" // Align items to the start of the container
  alignItems="center"
  gap="20px" // Space between items
  mb={3} // Bottom margin
  mt={2} // Top margin
  style={{
    width: '100%',
  }}
>
  {/* Search Bar */}
  <Box
    style={{
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '25px',
      backgroundColor: '#fff',
      width: '50%', // Width of the search bar
      padding: '5px 10px',
    }}
  >
    <TextField
      variant="outlined"
      placeholder="Search by Name or Email"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      size="small"
      InputProps={{
        startAdornment: (
          <span
            style={{
              marginRight: '8px',
              color: '#999',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i
              className="fas fa-search"
              style={{
                fontSize: '18px',
              }}
            ></i>
          </span>
        ),
      }}
      style={{
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '25px',
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '25px',
          '& fieldset': {
            border: 'none', // Removes the border
          },
          '&:hover fieldset': {
            border: 'none', // Ensures no border on hover
          },
          '&.Mui-focused fieldset': {
            border: 'none', // Removes border when focused
          },
        },
      }}
    />
  </Box>

  {/* Refresh Button */}
  <Button
    variant="contained"
    color="primary"
    startIcon={<i className="fas fa-sync-alt"></i>} // Add a refresh icon
    style={{
      flexShrink: 0,
      padding: '10px 20px',
      fontWeight: 'bold',
      backgroundColor: '#004080', // Dark blue color
      color: '#fff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }}
    onClick={handleRefresh}
  >
    Refresh
  </Button>

  {/* Export Button */}
  <Button
    variant="contained"
    color="secondary"
    startIcon={<SaveAltIcon />}
    onClick={exportToExcel}
    style={{
      flexShrink: 0,
      padding: '10px 20px',
      backgroundColor: '#1976d2', // Light blue color
      color: '#fff',
      fontWeight: 'bold',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }}
  >
    Export to Excel
  </Button>
</Box>
  <Box mb={2} p={2} bgcolor="#f5f5f5" borderRadius={1}>
  <Typography variant="h6" gutterBottom>Filters</Typography>
  <FormGroup row>
    <FormControlLabel
      control={
        <Checkbox
          checked={filters.portfolioImages === true}
          onChange={(e) => handleFilterChange('portfolioImages', e.target.checked)}
        />
      }
      label="Has Portfolio Images"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={filters.isEmailVerified === true}
          onChange={(e) => handleFilterChange('isEmailVerified', e.target.checked)}
        />
      }
      label="Email Verified"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={filters.hasDescription === true}
          onChange={(e) => handleFilterChange('hasDescription', e.target.checked)}
        />
      }
      label="Has Description"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={filters.socialMediaLinked === true}
          onChange={(e) => handleFilterChange('socialMediaLinked', e.target.checked)}
        />
      }
      label="Social Media Linked"
    />
  </FormGroup>
</Box>
  {/* Table */}
  <TableContainer
    component={Paper}
    style={{
      marginTop: '20px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px',
    }}
  >
    <Table style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
      <TableHead>
        <TableRow>
          {['Created At', 'Name', 'Email','Category','Sub Category', 'Phone', 'Portfolio Images', 'Email Verified', 'Description', 'Social Media Linked', 'Actions'].map((header, index) => (
            <TableCell
              key={index}
              style={{
                fontWeight: 'bold',
                borderBottom: '1px solid #ccc',
                backgroundColor: '#d4d4d4',
              }}
            >
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell colSpan={9} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        ) : currentPageData.length > 0 ? (
          currentPageData.map((row) => (
            <TableRow
              key={row.accountId}
              style={{
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#fff')}
            >
              {['accountCreatedAt', 'name', 'accountCategory','accountSubCategory','email', 'contact', 'portfolioImages', 'isEmailVerified', 'accountDescription', 'socialMediaLinked'].map((key, index) => (
                <TableCell
                  key={index}
                  style={{
                    borderBottom: '1px solid #ccc',
                    padding: '10px',
                  }}
                >
                  {key === 'isEmailVerified' || key === 'socialMediaLinked'
                    ? row[key]
                      ? 'Yes'
                      : 'No'
                    : row[key] }
                </TableCell>
              ))}
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleViewProfile(row.accountId)}
                >
                  View Profile
                </Button>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={9} align="center">
              No records found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>

  <Box display="flex" justifyContent="center" mt={2}>
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={(_, page) => setCurrentPage(page)}
      color="primary"
    />
  </Box>
</Container>
  );
};

export default WaitlistDashboard;
const convertToTitleCase = (camelCaseString) => {
  if (!camelCaseString || typeof camelCaseString !== 'string') {
    return ''; // Return an empty string if the input is not valid
  }
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add a space before each uppercase letter
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};



export const formatDate = (isoDate) => {
  if (!isoDate) return 'N/A';
  try {
    const cleanDate = isoDate.split('[')[0]; // Remove timezone if present
    return format(parseISO(cleanDate), 'MMM dd, yyyy h:mm a') + ' EST'; // Format: Nov 13, 2024 8:51 PM EST
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};