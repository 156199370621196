import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { FaChartBar, FaUsers, FaUserCircle, FaCogs, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase auth
import logo from '../assets/img/logo_primary.svg';


const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out the user using Firebase Authentication
      console.log("User logged out");
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Dynamic active button styling
  const isActive = (path) => location.pathname === path;

  return (
    <AppBar position="fixed" className="navbar" style={{ backgroundColor: '#f5f5f5' }}>
    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {/* Logo */}
      <div style={{ marginRight: '400px' }}>
        <img src={logo} alt="Logo" style={{ width: '80px', height: '80px' }} />
      </div>
  
      {/* Navbar Buttons */}
      <div style={{ display: 'flex', gap: '50px', alignItems: 'center', marginLeft: 'auto' }}>
        <Button
          startIcon={<FaChartBar />}
          style={{
            color: isActive('/waitlist-dashboard') ? '#0f1e35' : '#0f1e35',
            textTransform: 'none',
          }}
          onClick={() => navigate('/waitlist-dashboard')}
          onMouseEnter={(e) => (e.target.style.color = '#007BFF')} // Blue hover color
          onMouseLeave={(e) => (e.target.style.color = '#0f1e35')} // Original color
        >
          Waitlist Dashboard
        </Button>
        <Button
          startIcon={<FaUsers />}
          style={{
            color: isActive('/brand-ambassador') ? '#0f1e35' : '#0f1e35',
            textTransform: 'none',
          }}
          onClick={() => navigate('/brand-ambassador-dashboard')}
          onMouseEnter={(e) => (e.target.style.color = '#007BFF')} // Blue hover color
          onMouseLeave={(e) => (e.target.style.color = '#0f1e35')} // Original color
        >
          Brand Ambassador
        </Button>
        <Button
          startIcon={<FaUserCircle />}
          style={{
            color: isActive('/profile') ? '#0f1e35' : '#0f1e35',
            textTransform: 'none',
          }}
          onClick={() => navigate('/profile')}
          onMouseEnter={(e) => (e.target.style.color = '#007BFF')} // Blue hover color
          onMouseLeave={(e) => (e.target.style.color = '#0f1e35')} // Original color
        >
          Profile
        </Button>
        <Button
          startIcon={<FaCogs />}
          style={{
            color: isActive('/settings') ? '#0f1e35' : '#0f1e35',
            textTransform: 'none',
          }}
          onClick={() => navigate('/settings')}
          onMouseEnter={(e) => (e.target.style.color = '#007BFF')} // Blue hover color
          onMouseLeave={(e) => (e.target.style.color = '#0f1e35')} // Original color
        >
          Settings
        </Button>
      </div>
  
      {/* Logout Icon */}
      <IconButton
        onClick={handleLogout}
        style={{ color: '#0f1e35', marginLeft: '30px' }}
        title="Logout"
        onMouseEnter={(e) => (e.currentTarget.style.color = '#007BFF')} // Blue hover color
        onMouseLeave={(e) => (e.currentTarget.style.color = '#0f1e35')} // Original color
      >
        <FaSignOutAlt />
      </IconButton>
    </Toolbar>
  </AppBar>
  );
};

export default Navbar;