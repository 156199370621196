import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../assets/img/logo_primary.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/profile'); // Redirect on success
    } catch (err) {
      setError(err.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f9f9f9, #dbe4f4)', // Light pastel gradient
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column', // Align items vertically
        padding: '20px',
      }}
    >
      {/* Centered Logo */}
      <div style={{ marginBottom: '20px' }}>
        <img src={logo} alt="Logo" style={{ width: '200px', height: '200px' }} />
      </div>

      <Container maxWidth="xs">
        <Paper
          elevation={5}
          style={{
            padding: '40px 30px',
            borderRadius: '10px',
            textAlign: 'center',
            background: 'rgba(255, 255, 255, 0.95)', // Slightly transparent white
          }}
        >
          <Typography
            variant="h4"
            style={{
              marginBottom: '20px',
              fontWeight: 'bold',
              color: '#1976d2',
            }}
          >
            Welcome Back!
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginBottom: '20px',
              color: '#757575',
            }}
          >
            Login to access your dashboard.
          </Typography>
          {error && (
            <Typography
              color="error"
              variant="body2"
              style={{ marginBottom: '10px' }}
            >
              {error}
            </Typography>
          )}

          <form onSubmit={handleLogin}>
            <Box mb={3}>
              <TextField
                fullWidth
                label="Email Address"
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                InputProps={{
                  style: { fontSize: '16px' },
                }}
                InputLabelProps={{
                  style: { fontSize: '14px' },
                }}
              />
            </Box>

            <Box mb={3}>
              <TextField
                fullWidth
                label="Password"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { fontSize: '16px' },
                }}
                InputLabelProps={{
                  style: { fontSize: '14px' },
                }}
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{
                padding: '10px',
                fontSize: '16px',
                fontWeight: 'bold',
                textTransform: 'none',
                borderRadius: '25px',
              }}
            >
              Login
            </Button>
          </form>

          <Box mt={3}>
            <Typography variant="body2" style={{ color: '#757575' }}>
              Don't have an account?{' '}
              <span
                style={{
                  color: '#1976d2',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/register')}
              >
                Sign Up
              </span>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;