// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "AIzaSyDdr0CPaUgqyWfqzcGUsqZPvNTr6ul9Mbo",
//   authDomain: "fashionapp99.firebaseapp.com",
//   databaseURL: "https://fashionapp99-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "fashionapp99",
//   storageBucket: "fashionapp99.appspot.com",
//   messagingSenderId: "454460666537",
//   appId: "1:454460666537:web:4cc24b801a540a27c4afb8",
//   measurementId: "G-G2WH1FK92P"
// };
const firebaseConfig = {
  apiKey: "AIzaSyALNWVVKhhWvwM4YwkfSLVImD9RZ5Wwg6M",
  authDomain: "yards-backend-prod.firebaseapp.com",
  projectId: "yards-backend-prod",
  storageBucket: "yards-backend-prod.appspot.com",
  messagingSenderId: "887960186002",
  appId: "1:887960186002:web:55147f3c1b1b08a3924a4b",
  measurementId: "G-15XQD3D65W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the Firebase Auth instance
export const auth = getAuth(app);
